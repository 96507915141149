<template>
  <div class="d-inline">
    <v-menu offset-y open-on-hover>
      <template #activator="{ on }">
        <v-btn
          v-if="$store.getters.isAdmin && $store.getters.auth"
          color="primary"
          :icon="!$vuetify.breakpoint.lgAndUp"
          :text="btnText"
          v-on="on"
        >
          <slot name="label">
            <span v-if="$vuetify.breakpoint.lgAndUp && label">Admin</span>
          </slot>
          <v-icon>mdi-account-cog</v-icon></v-btn
        >
      </template>
      <v-list nav min-width="200">
        <div v-for="(nav, index) in navs" :key="index">
          <v-btn
          v-if="showAdminBtn(nav.value)"
            color="primary"
            text
            class="justify-start text-capitalize my-2"
            block
            large
            :to="nav.to"
            :href="nav.href"
          >
            {{ nav.text }}
            <v-icon v-if="nav.icon">
              {{ nav.icon }}
            </v-icon>
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import config from '../config';

const defaultNavs = () => {
  return [
    {
      text: 'Admin',
      to: '/admin/dashboard',
      icon: 'mdi-view-dashboard',
      value: 'peepsportalAdminDashboard'
    },
    {
      text: 'Email',
      to: '/admin/email',
      icon: 'mdi-email',
      value: 'peepsportalAdminEmail'
    },
    {
      text: 'Sandbox',
      to: '/admin/sandbox',
      icon: 'mdi-cube-outline',
      value: 'peepsportalAdminSandbox'
    }
  ];
};

export default {
  props: {
    label: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      navs: defaultNavs()
    };
  },
  computed: {
    path() {
      return this.$route.path;
    }
  },
  mounted() {
    this.generate();
  },
  watch: {
    path: 'generate'
  },
  methods: {
    showAdminBtn(value){
      return this.$store.state.admin.permission[value]
    },
    generate() {
      this.navs = defaultNavs();
      const { type, name } = this.$store.state.loggedInAs;
      const org = type === 'organization' ? name : '';

      const fromPathAdmin = `${window.location.origin}${this.path}`;

      if (config.deployment === 'main') {
        this.navs.push({
          text: 'CMS',
          href: `https://cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
          icon: 'mdi-file-document-edit',
          value: 'peepsportalAdminCms'
        });
      } else if (config.deployment === 'beta') {
        this.navs.push({
          text: 'CMS',
          href: `https://beta-cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
          icon: 'mdi-file-document-edit',
          value: 'peepsportalAdminCms'
        });
      } else {
        this.navs.push({
          text: 'CMS',
          href: `https://dev-cms.peepsportal.org/admin?organization=${org}&pathHome=${fromPathAdmin}`,
          icon: 'mdi-file-document-edit',
          value: 'peepsportalAdminCms'
        });
      }
    }
  }
};
</script>

<style>
</style>
