import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline"},[_c(VMenu,{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.getters.isAdmin && _vm.$store.getters.auth)?_c(VBtn,_vm._g({attrs:{"color":"primary","icon":!_vm.$vuetify.breakpoint.lgAndUp,"text":_vm.btnText}},on),[_vm._t("label",[(_vm.$vuetify.breakpoint.lgAndUp && _vm.label)?_c('span',[_vm._v("Admin")]):_vm._e()]),_c(VIcon,[_vm._v("mdi-account-cog")])],2):_vm._e()]}}],null,true)},[_c(VList,{attrs:{"nav":"","min-width":"200"}},_vm._l((_vm.navs),function(nav,index){return _c('div',{key:index},[(_vm.showAdminBtn(nav.value))?_c(VBtn,{staticClass:"justify-start text-capitalize my-2",attrs:{"color":"primary","text":"","block":"","large":"","to":nav.to,"href":nav.href}},[_vm._v(" "+_vm._s(nav.text)+" "),(nav.icon)?_c(VIcon,[_vm._v(" "+_vm._s(nav.icon)+" ")]):_vm._e()],1):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }